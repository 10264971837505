import React from 'react'

const Banner = () => {
    return (
        <div style={{
            backgroundColor: 'rgb(255, 68, 0)',
            color: 'white',
            padding: 20,
            paddingTop: 20,
            paddingBottom: 20,
            display: 'flex',
            alignItems: 'center'
        }} class="flex-dir-row mobile-flex-dir-col">
            <div style={{flex: 1}}>
                <b>Good luck to the class of 2020!</b>
            </div>
        </div>
    )
}

export default Banner
